import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import Header from "./Header";
import NavHeader from "./NavHeader";
import Footer from "./Footer";
import axios from "axios";
import { Helmet } from "react-helmet";
const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(6); // Number of blogs per page
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.adiance.com:443/api/blogs/getAllBlogs?page=${currentPage}`
          // `http://localhost:8007/api/blogs/getAllBlogs?page=${currentPage}`
        );
        const { blogs, totalPages } = response.data;
        setBlogs(blogs);
        setFilteredBlogs(blogs);
        setTotalPages(totalPages);
      } catch (error) {
        setError(
          error.message || "Failed to fetch blogs. Please try again later."
        );
      }
    };
    // setUpdatedData(fetchData);
    fetchData();
  }, [currentPage, limit]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Function to truncate the description to 50 words
  const truncateDescription = (description, maxWords = 50) => {
    const words = description.split(/\s+/);
    const truncated = words.slice(0, maxWords).join(" ");
    if (words.length > maxWords) {
      return truncated + "...";
    }
    return truncated;
  };

  // Function to format date to display only the date portion
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString(); // Returns date in "Day Month Date Year" format (e.g., "Fri Mar 20 2024")
  };

  return (
    <div>
      <Helmet>
        <title>News and Media | Adiance Blog</title>
        <meta
          name="description"
          content="Adiance Blog: Adiance is the leading and fast growing company in terms of cctv manufacturing and well recognized by media and is a growing pioneer."
        />
      </Helmet>
      <Header />
      <NavHeader text={"Blogs"} />
      <div className="innovation-container">
        {filteredBlogs.map((blog, index) => (
          <div className="innovation-item" key={blog._id}>
            <img
              src={`https://backend.adiance.com:443/images/${blog.image}`}
              alt={blog.title}
              className="item-image"
            />

            <div className="item-details">
              <h3 className="item-title">{blog.title}</h3>
              <br />
              <p className="item-description">
                {truncateDescription(blog.description)}
              </p>{" "}
              {/* Use the truncateDescription function */}
              <div className="item-footer">
                <p className="item-date">{formatDate(blog.date)}</p>{" "}
                {/* Use the formatDate function */}
                <Link to={`/blog/${blog._id}`} className="read-more-link">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Typography variant="body1">
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          style={{ marginRight: "10px" }}
        >
          Previous
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
      <Footer />
      <style jsx>{`
        .innovation-container {
          display: grid;
          grid-gap: 20px;
          padding: 20px;
          margin: 5% 10%;
        }

        .innovation-item {
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .item-image {
          width: 100%;
          height: auto;
          margin-bottom: 10px;
        }

        .item-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .item-description {
          font-size: 14px;
          color: #666;
        }

        .item-footer {
          display: flex;
          justify-content: space-between;
        }

        .item-date {
          font-size: 14px;
          color: #666;
        }

        .read-more-link {
          font-size: 14px;
          color: blue;
          text-decoration: none;
        }

        @media screen and (min-width: 768px) {
          .innovation-container {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        @media screen and (min-width: 1024px) {
          .innovation-container {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      `}</style>
    </div>
  );
};

export default Blogs;
