import React from "react";
import Header from "./Header";
import NavHeader from "./NavHeader";
import Footer from "./Footer";
import AboutMidSection from "./AboutMidSection";
import { Helmet } from "react-helmet";
export default function () {
  return (
    <div>
      <Helmet>
        <title>
          About Us - Adiance Security Camera Manufacturers Most Popular Company
          in USA
        </title>
        <meta
          name="description"
          content="Adiance is among the largest CCTV manufacturer in the world, working with more than 20 different clients with experience of more than 18 years."
        />
      </Helmet>
      <Header />
      <NavHeader text={"About Us"} />
      <AboutMidSection />
      <Footer />
    </div>
  );
}
