import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../styles/bootNavbar.css";
import { Link as RouterLink } from "react-router-dom";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const ResponsiveNavbar = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const location = useLocation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = screenWidth <= 993; // Adjust the threshold as needed
  return (
    <Navbar
      expand="lg"
      bg="dark"
      className="bg-body-tertiary"
      data-bs-theme="dark"
    >
      {isSmallScreen && (
        <Navbar.Brand
          as={Link}
          to="/"
          style={{ marginLeft: "10%", marginRight: "10%" }}
        >
          <img
            src="/images/Logo-241x47-1.png"
            width="auto"
            height="30"
            className="d-inline-block align-top"
            alt="Your Logo"
          />
        </Navbar.Brand>
      )}
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        style={{ marginRight: "5%" }}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link
            as={Link}
            to="/"
            style={{
              marginRight: "2.5%",
              whiteSpace: "nowrap",
              color: location.pathname === "/" ? "white" : "#A9A9A9",
              // fontWeight: location.pathname === "/about" ? "bold" : "normal",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
              marginLeft: screenWidth > 1168 ? "24%" : "2.5%",
            }}
          >
            Home
          </Nav.Link>

          <Nav.Link
            as={Link}
            to="/innovation"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
              color: location.pathname === "/innovation" ? "white" : "#A9A9A9",
            }}
          >
            Innovation
          </Nav.Link>

          {/* <NavDropdown
            title="Technology"
            id="basic-nav-dropdown"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
              // display: "flex", // Apply Flexbox to the NavDropdown
              // flexDirection: "row", // Horizontal arrangement
              // flexWrap: "wrap", // Wrap items to next line if needed
            }}
          >
            <NavDropdown.Item
              as={Link}
              to="/innovation"
              style={{ fontSize: screenWidth > 993 ? "18px" : "inherit" }}
            >
              Innovation
            </NavDropdown.Item>
          </NavDropdown> */}

          <NavDropdown
            title="Products"
            id="basic-nav-dropdown"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
            }}
          >
            {screenWidth > 992 ? (
              <NavDropdown.Item
                style={{ fontSize: screenWidth > 993 ? "18px" : "inherit" }}
                className="no-hover"
              >
                <table style={{ marginRight: "40px" }}>
                  <tbody>
                  <tr>
                    <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/3MP-4G-Bullet-All-Time-Color-Camera-A-Series"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              A Series
                            </RouterLink>
                          </li>
                        </ul>
                      </td>

                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/H-Series"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              H Series
                            </RouterLink>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                    <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/AI-3MP-Nexgen-4G-PT-Camera-S-Series"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              S Series
                            </RouterLink>
                          </li>
                        </ul>
                      </td>

                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/R-Series"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              R Series
                            </RouterLink>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/thermalCamera"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              Thermal Camera
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink
                              to="/adiance-thermal-camera-f"
                              className="link"
                            >
                              Adiance Thermal Camera - F
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink
                              to="/adiance-thermal-camera-n"
                              className="link"
                            >
                              Adiance Thermal Camera - N
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink
                              to="/adiance-thermal-camera-l"
                              className="link"
                            >
                              Adiance Thermal Camera - L
                            </RouterLink>
                          </li>
                        </ul>
                      </td>

                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/CloudXVR"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              Cloud XVR
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/cloudXVR" className="link">
                              Adiance 8-16Channel XVR
                            </RouterLink>
                          </li>
                          <li>
                            <br />
                          </li>
                          <li>
                            <br />
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/ANPRCamera"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              ANPR Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/edge-ai-based-ptz-anpr-bullet-camera"
                              className="link"
                            >
                              Edge Ai based PTZ ANPR Camera
                            </RouterLink>
                          </li>
                          <li>
                            <br />
                          </li>
                          <li>
                            <br />
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/4KCamera"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              4K Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/4K-Bullet-ANPR-PTZ-Camera"
                              className="link"
                            >
                              4K Bullet ANPR PTZ Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/4K-Face-Recognition-Camera"
                              className="link"
                            >
                              4K Face Recognition Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/Edge-AI-Based-Object-n-Face-Detection-Cameras"
                              className="link"
                            >
                              4K Object & Face Detection Cameras
                            </RouterLink>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/EdgeAICamera"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              Edge-Ai Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/edge-ai-based-ptz-anpr-bullet-camera-3"
                              className="link"
                            >
                              Edge AI Based PTZ ANPR Bullet Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/edge-ai-based-recognition-camera"
                              className="link"
                            >
                              Edge AI Based Face Recognition Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/Edge-AI-Based-Object-n-Face-Detection-Camera"
                              className="link"
                            >
                              Edge AI Based Object & Face Detection Cameras
                            </RouterLink>
                          </li>
                          <li>
                            <br />
                          </li>
                        </ul>
                      </td>

                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="/4GCamera"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              4G Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/4G-Dome-PTZ-Camera"
                              className="link"
                            >
                              4G Dome PTZ Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/4G-Mini-Bullet-Camera"
                              className="link"
                            >
                              4G Mini Bullet Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/4G-Dome-Camera" className="link">
                              4G Dome Camera
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink
                              to="/H265-4G-Dome-PTZ-Camera"
                              className="link"
                            >
                              H.265+ 4G Dome PTZ Camera
                            </RouterLink>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </NavDropdown.Item>
            ) : (
              <>
              <NavDropdown.Item
                    as={Link}
                    to="/3MP-4G-Bullet-All-Time-Color-Camera-A-Series"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    A Series
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/H-Series"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    H Series
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/AI-3MP-Nexgen-4G-PT-Camera-S-Series"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    S Series
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/R-Series"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    R Series
                  </NavDropdown.Item>
                <NavDropdown
                  title="Thermal Camera"
                  id="basic-nav-dropdown"
                  style={{
                    paddingLeft: "5px",
                    fontSize: screenWidth > 993 ? "20px" : "inherit",
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/adiance-thermal-camera-f"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Adiance Thermal Camera - F
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/adiance-thermal-camera-n"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Adiance Thermal Camera - N
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/adiance-thermal-camera-l"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Adiance Thermal Camera - L
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Cloud XVR"
                  id="basic-nav-dropdown"
                  style={{
                    paddingLeft: "5px",
                    fontSize: screenWidth > 993 ? "20px" : "inherit",
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/cloudXVR"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Adiance 8-16Channel XVR
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="ANPR Camera"
                  id="basic-nav-dropdown"
                  style={{
                    paddingLeft: "5px",
                    fontSize: screenWidth > 993 ? "20px" : "inherit",
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/edge-ai-based-ptz-anpr-bullet-camera"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Edge AI Based PTZ ANPR Bullet Camera
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="4K Camera"
                  id="basic-nav-dropdown"
                  style={{
                    paddingLeft: "5px",
                    fontSize: screenWidth > 993 ? "20px" : "inherit",
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/4K-Bullet-ANPR-PTZ-Camera"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    4K Bullet ANPR PTZ Camera
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/4K-Face-Recognition-Camera"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    4K Face Recognition Camera
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/Edge-AI-Based-Object-n-Face-Detection-Cameras"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    4K Object & Face Detection Cameras
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Edge-Ai Camera"
                  id="basic-nav-dropdown"
                  style={{
                    paddingLeft: "5px",
                    fontSize: screenWidth > 993 ? "20px" : "inherit",
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/edge-ai-based-ptz-anpr-bullet-camera-3"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Edge AI Based PTZ ANPR Bullet Camera
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/edge-ai-based-recognition-camera"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Edge AI Based Face Recognition Camera
                  </NavDropdown.Item>
                  <Nav.Item style={{ width: "100%" }}>
                    <NavDropdown.Item
                      as={Link}
                      to="/Edge-AI-Based-Object-n-Face-Detection-Camera"
                      style={{
                        fontSize: screenWidth > 993 ? "20px" : "inherit",
                      }}
                    >
                      {window.innerWidth < 466 ? (
                        <>
                          Edge AI Based Object & Face
                          <br />
                          Detection Cameras
                        </>
                      ) : (
                        "Edge AI Based Object & Face Detection Cameras"
                      )}
                    </NavDropdown.Item>
                  </Nav.Item>
      
                </NavDropdown>

                <NavDropdown
                  title="4G Camera"
                  id="basic-nav-dropdown"
                  style={{
                    paddingLeft: "5px",
                    fontSize: screenWidth > 993 ? "20px" : "inherit",
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/4G-Dome-PTZ-Camera"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    4G Dome PTZ Camera
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/4G-Mini-Bullet-Camera"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    4G Mini Bullet Camera
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/4G-Dome-Camera"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    4G Dome Camera
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/H265-4G-Dome-PTZ-Camera"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    H.265+ 4G Dome PTZ Camera
                  </NavDropdown.Item>
                  
                </NavDropdown>
                
                  
              </> // Empty fragment when screenWidth <= 992
            )}
          </NavDropdown>

          <NavDropdown
            title="Solutions"
            id="basic-nav-dropdown"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
            }}
          >
            {screenWidth > 992 ? (
              <NavDropdown.Item
                style={{ fontSize: screenWidth > 993 ? "18px" : "inherit" }}
                className="no-hover"
              >
                <table style={{ marginRight: "40px" }}>
                  <tbody>
                    <tr>
                      <td>
                        <ul>
                          <li>
                            <RouterLink
                              to="#"
                              className="link h6"
                              // style={{ margin: "20%" }}
                            >
                              Scenario Based
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink to="/Public-safety" className="link">
                              Public Safety and Security
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink
                              to="/Trafic-Management"
                              className="link"
                            >
                              Traffic Management & Monitoring
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink to="/Crowd-Control" className="link">
                              Crowd Control
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink to="/Smart-Cities" className="link">
                              Smart Cities & Infrastructure
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink to="/Remote-Security" className="link">
                              Remote-Security
                            </RouterLink>
                          </li>
                          <li>
                            {/* <DoubleArrowIcon className="doubleArrow" /> */}
                            <ArrowRightIcon />
                            <RouterLink to="/High-Traffic" className="link">
                              High-Traffic Infrastructure
                            </RouterLink>
                          </li>
                        </ul>
                      </td>

                      <td>
                        <ul>
                          <li>
                            <RouterLink to="#" className="link h6">
                              Industry based
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/Education" className="link">
                              Education
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/Healthcare" className="link">
                              Healthcare
                            </RouterLink>
                          </li>

                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/Public-Transport" className="link">
                              Public Transport
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/Retail" className="link">
                              Retail
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/Smart-Safe-City" className="link">
                              Smart & Safe city
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/bank-finance" className="link">
                              Banking & Finance
                            </RouterLink>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </NavDropdown.Item>
            ) : (
              <>
                <NavDropdown
                  title="Scenario Based"
                  id="basic-nav-dropdown"
                  style={{
                    paddingLeft: "5px",
                    fontSize: screenWidth > 993 ? "20px" : "inherit",
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/Public-safety"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Public Safety and Security
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Trafic-Management"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Traffic Management & Monitoring
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Crowd-Control"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Crowd Control
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Smart-Cities"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Smart Cities & Infrastructure
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Remote-Security"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Remote Security Surveillance
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/High-Traffic"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    High-Traffic Infrastructure
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Industry based"
                  id="basic-nav-dropdown"
                  style={{
                    paddingLeft: "5px",
                    fontSize: screenWidth > 993 ? "20px" : "inherit",
                  }}
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/Education"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Education
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/Healthcare"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Healthcare
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/Public-Transport"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Public Transport
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Retail"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Retail
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Smart-Safe-City"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Smart & Safe city
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/bank-finance"
                    style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                  >
                    Banking & Finance
                  </NavDropdown.Item>
                </NavDropdown>
              </> // Empty fragment when screenWidth <= 992
            )}
          </NavDropdown>

          {/* <NavDropdown
            title="Support"
            id="basic-nav-dropdown"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
            }}
          > */}
          {/* <NavDropdown.Item as={Link} to="/downloads">
              Download Center
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/datasheet">
              Datasheet
            </NavDropdown.Item> */}
          {/* <NavDropdown.Item
              as={Link}
              to="/feedback"
              style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
            >
              Feedback
            </NavDropdown.Item> */}
          {/* </NavDropdown> */}

          {/* <Nav.Link
            as={Link}
            to="/feedback"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
              color: location.pathname === "/feedback" ? "white" : "#A9A9A9",
            }}
          >
            Feedback
          </Nav.Link> */}

          <NavDropdown
            title="Support"
            id="basic-nav-dropdown"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
            }}
          >
            {screenWidth > 992 ? (
              <NavDropdown.Item
                style={{ fontSize: screenWidth > 993 ? "18px" : "inherit" }}
                className="no-hover"
              >
                <table style={{ marginRight: "40px" }}>
                  <tbody>
                    <tr>
                      <td>
                        <ul>
                          <li>
                            <RouterLink to="/support" className="link h6">
                              Support
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/warranty-service" className="link">
                              Warranty Service
                            </RouterLink>
                          </li>
                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/warranty-policy" className="link">
                              Warranty Policy
                            </RouterLink>
                          </li>

                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/terms-of-service" className="link">
                              Terms of Service
                            </RouterLink>
                          </li>

                          <li>
                            <ArrowRightIcon />
                            <RouterLink to="/feedback" className="link">
                              Feedback
                            </RouterLink>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </NavDropdown.Item>
            ) : (
              <>
                <NavDropdown.Item
                  as={Link}
                  to="/warranty-service"
                  style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                >
                  Warranty Service
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/warranty-service"
                  style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                >
                  Warranty Policy
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/terms-of-service"
                  style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                >
                  Terms of Service
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/feedback"
                  style={{ fontSize: screenWidth > 993 ? "20px" : "inherit" }}
                >
                  Feedback
                </NavDropdown.Item>
              </> // Empty fragment when screenWidth <= 992
            )}
          </NavDropdown>

          <Nav.Link
            as={Link}
            to="/blogs"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
              color: location.pathname === "/blogs" ? "white" : "#A9A9A9",
            }}
          >
            Blogs
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/about"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              whiteSpace: "nowrap",
              color: location.pathname === "/about" ? "white" : "#A9A9A9",
              // fontWeight: location.pathname === "/about" ? "bold" : "normal",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
            }}
          >
            About Us
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/contact"
            style={{
              marginLeft: "2.5%",
              marginRight: "2.5%",
              whiteSpace: "nowrap",
              color: location.pathname === "/contact" ? "white" : "#A9A9A9",
              fontSize: screenWidth > 993 ? "18px" : "inherit",
            }}
          >
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default ResponsiveNavbar;
